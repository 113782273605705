<template>
  <div>
    <b-card>
      <h1
        class="mb-1"
      >
        Painel Cursos NR
      </h1>
      <b-form-group
        v-if="$can('VISUALIZAR','MODULO_CURSOS_NR_PAINEL_POR_EMPRESA_ACESSO_OPERADOR')"
        label="Selecione uma região"
        label-for="report-region-select"
      >
        <v-select
          id="report-region-select"
          v-model="region.regionSelected"
          :options="region.regions"
          placeholder="Todos"
          :clearable="false"
          label="descricao"
          item-text="descricao"
          item-value="id_regiao"
          :reduce="option => option.id_regiao"
          @input="prepareReport(true)"
          :disabled="showReportComponent && loadingReport"
        >
          <span slot="no-options">Nenhuma opção selecionável.</span>
        </v-select>
      </b-form-group>
      <div
        v-if="showReportComponent"
        class="my-2"
      >
        <b-overlay
          :show="loadingReport"
          rounded="lg"
          opacity="1"
        >
          <PowerBIReport
            v-if="!loadingReport && embedConfig.accessToken"
            :embed-config="embedConfig"
          />
        </b-overlay>
      </div>
    </b-card>
  </div>
</template>

<script>
import { BCard, BFormGroup, BOverlay } from "bootstrap-vue";
import PowerBIReport from "@/views/components/power-bi-embedded/PowerBIReport.vue";
import vSelect from 'vue-select'
import { modalGenericFillableErrorWithRedirectHome } from '@/libs/sweetalerts'

export default {
  components: {
    PowerBIReport,
    BCard,
    vSelect,
    BFormGroup,
    BOverlay
  },
  data() {
    return {
      showReportComponent: false,
      loadingReport: true,
      embedConfig: {
        groupId: null,
        reportId: null,
        accessToken: null
      },
      region: {
        regionSelected: null,
        regions: []
      }

    };
  },

  mounted() {
    if(
      this.$can('VISUALIZAR','MODULO_CURSOS_NR_PAINEL_POR_EMPRESA_ACESSO_OPERADOR')
    ){
      this.getRegions()
    }else{
      this.prepareReport()
    }
  },

  methods: {
    getRegions() {
      this.$http.get(this.$api.RegionaisBusca)
        .then(res => {
          if(res?.data && res.data !== ''){
            this.region.regions = res.data
          }
        })
        .catch(() => {
          modalGenericFillableErrorWithRedirectHome(
            'Ocorreu um erro inesperado, tente novamente mais ' +
            'tarde ou entre em contato com o suporte.'
          )
        })
    },
    async prepareReport(findByRegion = false) {
      this.showReportComponent = true;
      this.loadingReport = true;

      const params = findByRegion ? {
        'id_regiao': this.region.regionSelected
      } : null;

      await this.$http.get(this.$api.getCursosNR, { params })
         .then(res => {
          if(res?.data){
            this.embedConfig.groupId = res.data.groupId;
            this.embedConfig.reportId = res.data.reportId;
            this.embedConfig.accessToken = res.data.accessToken;
            this.loadingReport = false;
          }
        })
        .catch(error => {
          if (error?.response?.status === 404) {
            modalGenericFillableErrorWithRedirectHome(
              'O relatório solicitado não pôde ser encontrado. ' +
              'Verifique se os dados do relatório foram salvos corretamente e ' +
              'tente novamente. Caso precise de ajuda, entre em contato com o suporte técnico para obter assistência.'
            );
          }else{
            modalGenericFillableErrorWithRedirectHome(
              'Desculpe, ocorreu um erro inesperado na aplicação. Por favor, ' +
              'tente novamente mais tarde ou entre em contato com o suporte ' +
              'técnico para obter assistência.'
            );
          }
        })
    }
  }
};
</script>
